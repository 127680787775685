import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Router } from "src/models/Router";
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import OrderList from 'src/pages/OrderList';
import BarChartIcon from '@mui/icons-material/BarChart';

export const routers: Router[] = [
    {
        component: OrderList,
        label: 'Order list',
        link: '/order-list',
        icon: <ShoppingCartIcon fontSize="small" />,
        isDisplay: true,
    },
    {
        component: Dashboard,
        label: 'Dashboard (Upcoming)',
        link: '',
        icon: <BarChartIcon fontSize="small" />,
        isDisplay: true,
    },
    {
        component: Login,
        label: 'Login',
        link: '/login',
        isDisplay: false,
    },
]


export const routersMap = new Map(routers.map((item) => [item.link, item]));
