import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { createBrowserHistory } from 'history';
import { InfoMe } from '@Core/model/InfoMe';
import Layout from 'src/components/Layout';

export const history = createBrowserHistory({});

export function AuthenRoute({ ...props }: iProtectRoute) {
    return useMemo(
        () => (
            <Route
                exact={props.exact}
                path={props.path}
                render={({ location }) =>
                    Boolean(props.authen?.role) ? (
                        <Layout rightContent={<props.component />}></Layout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location },
                            }}
                        />
                    )}
            />
        ),
        [props],
    );
}

interface iProtectRoute extends iRoute {
    authen: InfoMe | undefined;
}

export interface iRoute {
    exact?: boolean;
    path: string;
    component: any;
    requireGuest?: boolean;
    requireAuth?: boolean;
}
