import { boolean } from "yup/lib/locale";
import { GlobalAction, ActionType, SetLoading } from "./GlobalActions";
import { GlobalState } from "./GlobalContexts";

export function globalReducer(state: GlobalState, action: GlobalAction): GlobalState {
    switch (action.type) {
        case ActionType.SetLoading:
            return { ...state, isLoading: action.payload.isLoading};
        default:
            return state;
    }
}

export const setLoading = (loading:boolean): SetLoading => ({
    type: ActionType.SetLoading,
    payload: {isLoading:loading},
});