import TextField from "@mui/material/TextField/TextField";
import withStyles from "@mui/styles/withStyles/withStyles";

export const StyledTextField = withStyles({
    root: {
        '&.MuiFormControl-root': {
            '&.MuiTextField-root': {
                // background: "rgba(255,255,255,0.2)",
                borderRadius: "5px"
            }
        },
        '& label': {
            color: 'white',
        },
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInputBase-input': {
            borderRadius: "5px",
            color: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                border: "2px solid white"
            },
            '&.Mui-focused fieldset': {
                border: "2px solid white"
            },
        },
    },
})(TextField);