import React, { useState } from 'react'
import { routers } from '../constants/Routers';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'src/theme/MuiTheme';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { Grid, Box, Typography, MenuList, MenuItem, ListItemIcon, Button } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { Router } from 'src/models/Router';
import { NavLink, useHistory } from 'react-router-dom';
import { dispatch } from 'src/rematch/store';

const useStyles = makeStyles({
    menuLayout: {
        background: theme.palette.primary.light,
        padding: "80px 0",
        minHeight: "100vh"
    },
    logo: {
        background: "white",
        display: "flex",
        width: "calc(100% - 100px)",
        borderRadius: "0 50px 0 0",
        padding: "15px 30px",
        justifyContent: "space-between",
        alignItems: "flex-end",
        boxShadow: "0 0.15rem 1.75rem 0 rgb(33 40 50 / 25%)"
    },
    bottomMenu: {
        padding: "40px 30px",
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0
    },
    menu: {
        '& a': {
            color:"rgba(0,0,0,0.5)",
            textDecoration: "none"
        }
    },
    selectedMenuItem: {
        opacity: 1,
        '&.Mui-selected': {
            background: "rgba(4,191,173,0.8) !important",
            width:"80%",
            borderRadius:"0 50px 50px 0"
        },
        '& a': {
            color: "white"
        },
        '& .MuiSvgIcon-fontSizeSmall': {
            color: "white",
        }
    }
})

export default function Menu() {
    const classes = useStyles()
    const [state, setState] = useState<{
        menu: Router[];
        idSelectedMenu: string;
    }>({
        menu: [],
        idSelectedMenu: "/order-list",
    });
    const history = useHistory();

    return (
        <Grid className={classes.menuLayout}>
            <Box className={classes.logo} mb={20}>
                <Typography color="primary" style={{ textDecoration: "underline" }} variant="body2">Ale Hm Extension</Typography>
                <img alt="Ale Tech" src="/aletech.png" style={{ height: 40 }} />
            </Box>
            <Box>
                <MenuList>
                    {routers.filter(r => r.isDisplay).map((r, index) => {
                        return (
                            <MenuItem selected={r.link == state.idSelectedMenu} classes={{ selected: classes.selectedMenuItem, root: classes.menu }}>
                                <ListItemIcon>
                                    {r.icon}
                                </ListItemIcon>
                                <NavLink
                                    to={r.link || '/'}
                                    onClick={(e) => {
                                        if (r.link && r.link.length !== 0 && r.link != state.idSelectedMenu) {
                                            history.push(r.link);
                                            setState({ ...state, menu: [], idSelectedMenu: '' });
                                        }
                                        e.stopPropagation();
                                        e.preventDefault();
                                    }}
                                >
                                    <Typography variant="inherit" >{r.label}</Typography>
                                </NavLink>
                            </MenuItem>
                        )
                    })}
                </MenuList>
            </Box>
            <Box className={classes.bottomMenu}>
                <Button fullWidth variant="contained" color="secondary" onClick={() => { dispatch.authen.logOut() }}>
                    <ExitToAppIcon style={{ marginRight: 8, fontSize: 20 }} />Log out</Button>
            </Box>
        </Grid>
    )
}
