import { IUserController } from "@Core/controllers/IUserController";
import { InfoMe } from "@Core/model/InfoMe";
import { User, UserAccount } from "@Core/model/User";
import { AxiosInstance } from "axios";
import { ListFilter, Paging } from "luong-base-model/lib";
import { BaseController } from "./BaseController";

export class UserController
  extends BaseController<User>
  implements IUserController
{
  constructor(serviceUrl: string, client: AxiosInstance) {
    super(serviceUrl, "user", client);
  }
  getByJwt(params: { userId: string }): Promise<InfoMe> {
    return this.client
      .get(`${this.serviceURL}/${this.basePath}/get-jwt-for-admin`, {
        params: params,
      })
      .then((res) => {
        return res.data;
      });
  }
  listUserForAdmin(
    params: ListFilter<UserAccount>
  ): Promise<Paging<UserAccount>> {
    return this.client
      .get(`${this.serviceURL}/${this.basePath}/lis-by-admin`, {
        params: params,
      })
      .then((res) => {
        return res.data;
      });
  }
  saveUserForAdmin(params: UserAccount): Promise<UserAccount> {
    return this.client
      .post(`${this.serviceURL}/${this.basePath}/save-by-admin`, params)
      .then((res) => {
        return res.data;
      });
  }
  register(params: UserAccount): Promise<User> {
    return this.client
      .post(`${this.serviceURL}/${this.basePath}/register`, params)
      .then((res) => {
        return res.data;
      });
  }
  getMe(): Promise<InfoMe> {
    return this.client
      .get(`${this.serviceURL}/${this.basePath}/getMe`)
      .then((res) => {
        return res.data;
      });
  }
  login(params: { username: string; password: string }): Promise<User> {
    return this.client
      .post(`${this.serviceURL}/${this.basePath}/login`, params)
      .then((res) => {
        return res.data;
      });
  }
}
