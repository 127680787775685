import React from "react";

export enum ActionType {
    SetLoading,
    SetNotification
}
export interface SetLoading {
    type: ActionType.SetLoading;
    payload: { isLoading: boolean }
}

export type GlobalAction = SetLoading