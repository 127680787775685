import createTheme from '@mui/material/styles/createTheme';
import color from '../constants/Color';

const theme = createTheme({
    palette: {
        primary: {
            main: color.primary,
            light:color.lightPrimary,
            dark:color.darkPrimary
        },
        secondary: {
            main: color.secondary,
            light:color.lightSecondary
        },
        warning: {
            main: color.warning,
        },
        success: {
            main: color.success,
        },
        error: {
            main: color.error,
        },
    },
    components:{
        MuiButton:{
            styleOverrides:{
                root:{
                    textTransform:"none",
                    fontsize: "1rem",
                    padding: "6px 24px",
                    fontWeight: 500,
                    borderRadius: 50
                },
                containedPrimary:{
                    color:"#fff"
                }
            }
        }
    }
});

export default theme;
