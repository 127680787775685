import { HMOrder } from "src/submodules/afi-manager-base-model/model/HmOrder";
import { formatCurrency } from "src/utils/Currency";
import { Column } from "./Column";

export const orderColumns: Column<HMOrder>[] = [
    { id: 'timestamp', label: 'Date', minWidth: 100 },
    { id: "email", label: 'Email', minWidth: 200 },
    {
        id: 'orderId',
        label: 'Order ID',
        minWidth: 100,
        align:'center'
    },
    {
        id: 'total',
        label: 'Giá mua',
        minWidth: 170,
        align: 'right',
        format:(value) => formatCurrency(value)
    },
];