import { Models } from '@rematch/core';
import { useDispatch } from 'react-redux';
import { authen } from './Authen';

export interface RootModel extends Models<RootModel> {
    authen: typeof authen;
}

export const models: RootModel = { authen};

export const useRematchDispatch = <D extends {}, MD>(selector: (dispatch: D) => MD) => {
    const dispatch = useDispatch<D>();
    return selector(dispatch);
};
