import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Pagination from '@mui/material/Pagination';
import moment from "moment";
import { orderColumns } from "../constants/OrderColumn";
import { hmOrderController } from "src/controllers";
import { HMOrder } from "src/submodules/afi-manager-base-model/model/HmOrder";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { HmOrderListFilter } from "@Core/controllers/IHmOrderController";
import { Paging } from "luong-base-model/lib/BaseModel";
import { Parser } from 'json2csv';
import { GlobalContext } from "src/constants/context/GlobalContexts";
import { setLoading } from "src/constants/context/GlobalReducers";
import useAuthen from "src/hook/useAuthen";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { countries } from "src/constants/Country";
import { formatCurrency } from "src/utils/Currency";

const fileDownload = require('js-file-download');

const useStyles = makeStyles({
  container: {
    padding: "80px 60px",
  },
  root: {
    width: "100%",
    maxWidth: "1100px",
    margin: "auto",
  },
  filter: {
    minWidth: "fit-content",
    margin: "10px 0"
  },
  textField: {
    borderRadius: "50px",
  },
});

let ONE_HOUR = 60 * 60 * 1000;
let ONE_DAY = 24 * ONE_HOUR;

export default function OrderList() {
  const { authen, isAdmin } = useAuthen()
  const classes = useStyles();
  const { dispatch } = useContext(GlobalContext);
  const [orders, setOrders] = useState<Paging<HMOrder>>({
    page: 1,
    pageSize: 50,
    rows: []
  });
  const [totalPrice, setTotalPrice] = useState<number>(0)

  const [query, setQuery] = useState<HmOrderListFilter>({
    search: "",
    searchFields: ["email", "orderId"],
    filter: {
      username: !isAdmin() ? `${authen.info?.email?.includes("@") ? authen.info?.email : `${authen.info?.email}@gmail.com`}` : undefined, 
      country: countries[0].code
    },
    customFilter: {
      timestamp: {
        from: new Date(new Date().getTime() - 24 * ONE_HOUR).getTime(),
        to: new Date(new Date().getTime() - 5 * ONE_HOUR).getTime()
      }
    },
    page: 1,
    pageSize: 50,
  });

  const selectedCountry = countries.find(c => c.code == query.filter?.country) || countries[0];

  useEffect(() => {
    dispatch(setLoading(true))
    hmOrderController.list(query).then((paging) => {
      dispatch(setLoading(false))
      setOrders(paging);
    });

    hmOrderController.list({...query, pageSize: 100000}).then(paging => setTotalPrice(paging.rows?.reduce((total, r) => total = total + r.total, 0) || 0))
  }, [query]);



  const exportExcel = async () => {
    const exportFields = ['country', 'username', 'date', 'email', 'orderId', 'total']
    let exportData = await hmOrderController.list({ ...query, pageSize: 100000 }).then((paging) => {
      return (paging.rows && paging.rows.length > 0 ?
        paging.rows.map(r => {
          let newR = {
            country: r.country,
            username: r.username || "null",
            date: (moment.unix(Number(r.timestamp / 1000)).format("DD/MM/YYYY")).toString(),
            email: r.email,
            orderId: r.orderId,
            total: r.total,
          }
          return newR
        }) : [])
    })
    const json2csvParser = new Parser({
      fields: exportFields ? exportFields : [],
      header: true,
    });
    const csv = exportData ? json2csvParser.parse(exportData) : null;
    csv && fileDownload(csv, `Order HM.csv`);
  };
  return (
    <Grid className={classes.container}>
      <Grid
        container
        direction="row" spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid sx={{ width: 2/ 5 }} className={classes.filter}>
          <TextField
            variant="outlined"
            value={query.search}
            label="Search"
            size="small"
            onChange={(e) =>
              setQuery({
                ...query,
                page: 1,
                search: e.target.value,
              })
            }
          />
          <Button
            color="primary"
            sx={{ marginLeft: 2 }}
            variant="contained"
            onClick={exportExcel}
            disabled={orders.total == 0}
          >
            Download excel
          </Button>
        </Grid>
        <Grid sx={{ width: 3/ 5 }} className={classes.filter}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                columnGap: 16,
              }}
            >
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Country</InputLabel>
                <Select
                  value={query.filter?.country || countries[0].code}
                  style={{height: 40}}
                  label="Country"
                  onChange={(e) => { setQuery({...query, filter:{...query.filter, country: e.target.value == "All"?undefined: e.target.value}}) }}
                >
                  {countries.map(c => <MenuItem value={c.code}>{c.name}</MenuItem>)}
                </Select>
              </FormControl>
              <DatePicker
                label="From"
                disableFuture
                minDate={new Date(2021, 0, 7, 0, 0, 0)}
                maxDate={query.customFilter?.timestamp.to ? query.customFilter?.timestamp.to : new Date()}
                inputFormat='dd/MM/yyyy'
                value={query.customFilter?.timestamp.from}
                renderInput={(params) => <TextField
                  sx={{ borderRadius: "50px" }}
                  size="small"
                  {...params} />}
                onChange={(e) => {
                  e && query.customFilter &&
                    setQuery({
                      ...query,
                      page: 1,
                      customFilter: {
                        ...query.customFilter,
                        timestamp: {
                          ...query.customFilter?.timestamp,
                          from: Number(moment(e).startOf('month').format("x"))
                        },
                      },
                    })
                }}
              />
              <DatePicker
                disableFuture
                inputFormat='dd/MM/yyyy'
                minDate={new Date(2021, 0, 7, 0, 0, 0)}
                renderInput={(params) =>
                  <TextField
                    sx={{ borderRadius: "50px" }}
                    size="small"
                    {...params} />}
                label="To"
                value={query.customFilter?.timestamp.to}
                InputProps={{
                  classes: { root: classes.textField },
                }}
                onChange={(e) => {
                  e && query.customFilter &&
                    setQuery({
                      ...query,
                      page: 1,
                      customFilter: {
                        ...query.customFilter,
                        timestamp: {
                          ...query.customFilter?.timestamp,
                          to: Number(moment(e).endOf('month').format("x"))
                        },
                      },
                    })
                }}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </Grid>

      <Typography variant="h6" color="secondary" mt={2}>Total paid:  {selectedCountry.symbol}{formatCurrency(totalPrice)}</Typography>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {orderColumns.map((column) => (
                <TableCell
                  key={String(column.id || "")}
                  style={{ minWidth: column.minWidth }}
                >
                  <Typography color="primary" align={column.align}>
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.rows && orders.rows.map((r) => {
              return (
                <TableRow key={r.orderId}>
                  {orderColumns.map((column) => {
                    const value = (r as any)[column.id];
                    return (
                      <TableCell key={String(column.id || "")} align={column.align}>
                        {column.id == "timestamp"
                          ? moment.unix(Number(value / 1000)).format("HH:mm DD/MM/YYYY")
                          : column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        {(orders?.total || 0) > 0 && <Pagination
          count={orders.totalPages}
          defaultPage={1} siblingCount={0}
          boundaryCount={2}
          showFirstButton showLastButton
          onChange={(e, v) => setQuery({
            ...query,
            page: Number(v)
          })}
          sx={{
            marginTop: "32px", display: "flex",
            justifyContent: "center"
          }} />}
      </TableContainer>
    </Grid>
  );
}
