import { useEffect, useReducer } from 'react';
import Login from './pages/Login';
import OrderList from './pages/OrderList';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import theme from './theme/MuiTheme';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from './rematch/store';
import { Route, Switch } from 'react-router-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { AuthenRoute } from './constants/RouterAuthen';
import { GlobalContext, initialGlobalState } from './constants/context/GlobalContexts';
import { globalReducer } from './constants/context/GlobalReducers';
import LoadingScreen from './components/LoadingScreen';


function App() {
  const authen = useSelector((state: RootState) => state.authen);
  const authenDispatch = useDispatch<Dispatch>();
  const [state, dispatch] = useReducer(globalReducer, initialGlobalState);

  useEffect(() => {
    const jwt = localStorage.getItem('jwt');
    jwt && jwt.length > 0 &&
      authenDispatch.authen.getMe();
  }, []);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {/* {state.isLoading && <LoadingScreen/>} */}
      <Router >
        <ThemeProvider theme={theme}>
          <Switch>
            <AuthenRoute
              exact
              component={OrderList}
              path='/order-list'
              authen={authen.info}
            />
            <Route path='/login' component={Login} />
            <Route component={Login} />
          </Switch>
        </ThemeProvider>
      </Router>
    </GlobalContext.Provider>
  );
}

export default App;

