import { Visibility, VisibilityOff } from '@mui/icons-material';
import Button from '@mui/material/Button/Button';
import IconButton from '@mui/material/IconButton/IconButton';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField/TextField';
import Typography from '@mui/material/Typography/Typography';
import makeStyles from '@mui/styles/makeStyles/makeStyles';
import { useEffect, useState } from 'react';
import { localStoryController, userController } from 'src/controllers';
import withStyles from '@mui/styles/withStyles/withStyles';
import { useFormik } from 'formik';
import Avatar from '@mui/material/Avatar/Avatar';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, RootState } from 'src/rematch/store';
import * as Yup from 'yup';
import { useHistory } from 'react-router';
import { StyledTextField } from 'src/components/StyledComponents';

const useStyles = makeStyles({
    background: {
        backgroundImage: "url(https://mocah.org/uploads/posts/587888-wood-nature.jpg)",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loginBox: {
        background: "rgba(255,255,255,0.2)",
        // border: "2px solid white",
        padding: "80px 30px 40px 30px",
        maxWidth: "400px",
        borderRadius: 8,
        display: "grid",
        gridRowGap: 32,
        position: "relative"
    },
    imgLogo: {
        background: "white",
        padding: "10px",
        position: "absolute !important" as any,
        top: "Calc(-25px)",
        left: "Calc((100% - 60px)/2)",
        '& img': {
            width: "90%",
            objectFit: "contain"
        }
    },
    errBox: {
        background: "rgba(255,255,255,0.5)",
        borderRadius: 5,
        padding:"10px",
        textAlign:"center"
    }
})
const validate = Yup.object({
    username: Yup.string().required('Do not leave username blank'),
    password: Yup.string().required('Do not leave the password blank'),
});

export default function Login() {
    const classes = useStyles()
    let history = useHistory();
    const dispatch = useDispatch<Dispatch>();
    const authen = useSelector((state: RootState) => state.authen);
    const [showPassword, setShowPassword] = useState(false)
    const [error, setError] = useState<string>('');

    const formik = useFormik<{ username: string; password: string }>({
        validationSchema: validate,
        initialValues: { password: '', username: '' },
        onSubmit: (values) => {
            userController
                .login({
                    password: values.password,
                    username: values.username,
                })
                .then((res: any) => {
                    localStoryController.addUserLogin({
                        ...formik.values,
                    });
                    dispatch.authen.login({
                        info: res,
                        jwt: res['jwt'],
                        role: res.role,
                    });
                })
                .catch((err) => {
                    setError('Incorrect account or password!');
                });
        },
    });
    const onSubmit = () => {
        formik.handleSubmit();
        formik.setTouched({
            password: true,
            username: true,
        });
    };

    useEffect(() => {
        if (authen.isGet) {
            console.log(authen);
            if (authen.info?.redirect) {
                console.log("hhh");
                history.push("/order-list");
            } else {
                // dispatch.notification.error('Permission not confirmed');
            }
        }
    }, [authen]);
    return (
        <Grid className={classes.background}>
            <form
                onSubmit={(e) => {
                    onSubmit();
                    e.preventDefault();
                    return false;
                }}
            >
                <Grid className={classes.loginBox} sx={{ boxShadow: 5 }}>
                    <Avatar alt="AleTech HM" src="/aletech.png" className={classes.imgLogo} sx={{ boxShadow: 5 }} />
                    {error && <Grid className={classes.errBox}>
                        <Typography color="error" variant="caption" align="center"> {error}</Typography>
                    </Grid>}
                    <StyledTextField
                        value={formik.values.username}
                        fullWidth
                        onChange={(e) => formik.setValues({ ...formik.values, username: e.target.value })}
                        onBlur={formik.handleBlur}
                        // error={!!(formik.touched.username && formik.errors.username)}
                        // helperText={formik.touched.username && formik.errors.username}
                        label="Username"
                        placeholder="Username"
                        variant="outlined"
                    />
                    <StyledTextField
                        value={formik.values.password}
                        fullWidth
                        label="Password"
                        placeholder="Password"
                        variant="outlined"
                        type={showPassword ? 'text' : 'password'}
                        onChange={(e) => formik.setValues({ ...formik.values, password: e.target.value })}
                        onBlur={formik.handleBlur}
                        // error={!!(formik.touched.password && formik.errors.password)}
                        // helperText={formik.touched.password && formik.errors.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>)
                        }}
                    />
                    <Button variant='contained' color="primary" size="large"
                        type={'submit'}
                    >
                        <Typography color="white" align="center">Đăng nhập</Typography></Button>
                </Grid>
            </form>
        </Grid>
    )
}
