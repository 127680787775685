import React from "react";
import { GlobalAction } from "./GlobalActions";

export interface GlobalState {
    isLoading?: boolean
}

export const initialGlobalState: GlobalState = {
    isLoading: false
}

export const GlobalContext = React.createContext<{
    state: GlobalState;
    dispatch: React.Dispatch<GlobalAction>;
}>({
    state: initialGlobalState,
    dispatch: () => undefined,
});
