import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'src/rematch/store';

const ADMIN_ROLE = '08298207-265c-4533-8029-de480a211808'
export default function useAuthen() {
    const authen = useSelector((state: RootState) => state.authen);
    const getRoleIds = () => authen.info?.role?.map(r => r.id) || []
    const isAdmin = () => getRoleIds().includes(ADMIN_ROLE)
    return {authen,isAdmin}
}
