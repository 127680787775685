
interface Country {
    code: string
    name: string
    symbol: string
  }
  export const countries: Country[] = [
    {
      code: 'ja_jp',
      name: "Japan",
      symbol:"¥"
    },
    {
      code: 'es_es',
      name: "Spain",
      symbol:"€"
    },
    {
      code: 'de_de',
      name: "Germany",
      symbol:"€"
    },
    {
      code: 'en_gb',
      name: "United Kingdom",
      symbol:"£"
    },
    {
      code: 'en_us',
      name: "United State",
      symbol:"$"
    },
    {
      code: 'pl_pl',
      name: "Poland",
      symbol:"€"
    }
  ]