import axios, { AxiosError } from "axios";
import { dispatch } from "src/rematch/store";
import { HMOrderController } from "./HMOrderController";
import { LocalStoryController } from "./LocalStoryController";
import { UserController } from "./UserController";

export const appClient = axios.create({
  timeout: 10000,
  headers: {
    common: {
      "Content-Type": "application/json",
    },
  },
});
appClient.interceptors.request.use(
  (res) => {
    return res;
  },
  (err: AxiosError) => { }
);

appClient.interceptors.response.use(
  (res) => {
    return res;
  },
  (err: AxiosError) => {
    if (err.message === "Network Error") {
      // window.location.href = "network-error"
    }
    if (err.response?.status === 401) {
      dispatch.authen.logOut()
      //   const linkNow = window.location.pathname;
      //   const getRoute = routersMap.get(linkNow);
    } else if (err.response?.status === 403) {
    } else {
    }
    return Promise.reject(err);
  }
);
export const localStoryController = new LocalStoryController();

export const hmOrderController = new HMOrderController(
  "https://api.afi.afivn.com",
  appClient
);

export const userController = new UserController(
  "https://api.afi.afivn.com",
  appClient
);
