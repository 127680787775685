import Menu from './Menu'
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    layout: {
        minHeight: "100vh",
    },
    menu: {
        position: "fixed",
        bottom: 0,
        top: 0,
        left: 0,
        width: "280px"
    },
    rightContent: {
        position: "absolute",
        // bottom: 0,
        top: 0,
        right: 0,
        width: "calc(100vw - 280px)"
    }
})

export default function Layout(props: Props) {
    const classes = useStyles();
    return (
        <div className={classes.layout}>
            <Grid className={classes.menu}>
                <Menu />
            </Grid>
            <Grid className={classes.rightContent}>
                {props.rightContent}
            </Grid>
        </div>
    )
}

interface Props {
    rightContent: JSX.Element
}