import { AxiosInstance } from "axios";
import { IHMOrderController } from "src/submodules/afi-manager-base-model/controllers/IHmOrderController";
import { HMOrder } from "src/submodules/afi-manager-base-model/model/HmOrder";
import { BaseController } from "./BaseController";

export class HMOrderController
  extends BaseController<HMOrder>
  implements IHMOrderController
{
  constructor(serviceUrl: string, client: AxiosInstance) {
    super(serviceUrl, "hm-order", client);
  }
}
